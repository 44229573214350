import { useQuery } from '@apollo/client'
import { toaster } from 'evergreen-ui'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { GET_STUDIES_ERROR_MESSAGE } from '../components/StudyDetails/studyUtils'
import { useGlobalStore } from './useGlobalStore'
import { GET_STUDIES_QUERY } from '../queries/getStudies'
import { ALL_REALMS_ID, TASKS } from '../utils/constants'
import { navigate } from 'gatsby'

export function useHandleStudiesList({ studiesSectionRef, routeRealm }) {
  const { realmId, setShowPrompt } = useGlobalStore()
  const [studiesSetRaw, setStudiesSetRaw] = useState([])
  const [studiesSet, setStudiesSet] = useState([])
  const [initialTabIndex, setInitialTabIndex] = useState(0)
  const [selectedStudy, setSelectedStudy] = useState()
  const [filterValue, setFilterValue] = useState('')
  const [showStudyDetail, setShowStudyDetail] = useState(false)
  const [showCreateStudyDialog, setShowCreateStudyDialog] = useState(false)

  // Realm Id that is set depending on the realm from URL and the global store
  const realRealmId =
    routeRealm === false ? -1 : routeRealm ? routeRealm : realmId

  function handleCreateStudyCloseModal() {
    setShowPrompt(false)
    setShowCreateStudyDialog(false)
  }
  function handleCreateStudyOpenModal() {
    setShowPrompt(true)
    setShowCreateStudyDialog(true)
  }

  function handleStudyDetailsClose() {
    setShowStudyDetail(false)
  }

  const { loading, data: studies } = useQuery(GET_STUDIES_QUERY, {
    skip: !realRealmId && realRealmId !== ALL_REALMS_ID,
    variables: {
      studyWhere: {
        realm_id: realRealmId > 0 ? { _eq: realRealmId } : {},
        isBlocked: { _eq: false },
        name: !realRealmId ? { _nlike: '%E2E-Study%' } : {}
      }
    },
    onError: _ => toaster.danger(GET_STUDIES_ERROR_MESSAGE)
  })

  useEffect(() => {
    function onGetStudiesSuccess(result) {
      const arr = result?.Study || []
      setStudiesSet(arr)
      setStudiesSetRaw(arr)
    }
    onGetStudiesSuccess(studies)
  }, [studies])

  function countUsers(sites = []) {
    const usersPerSites = Array.from(
      new Set(
        _.flatMap(sites, site => site.Site?.Site_Users || []).map(
          user => user.user_id
        )
      )
    )
    return usersPerSites.length
  }

  function handleOpenSideSheet(studyId, option = '') {
    if (studyId) {
      switch (
        option // decide what will be the initial tab for the study
      ) {
        case TASKS:
          setInitialTabIndex(1)
          break
        default:
          setInitialTabIndex(0)
      }
      setSelectedStudy({ id: studyId })
      setShowStudyDetail(true)
      navigate(`/realm/${realRealmId}/study/${studyId}`)
      /*       if (studiesSectionRef) {
        studiesSectionRef.current.classList.add('hide')
      } */
    }
  }

  function onStudyCreation(study) {
    handleOpenSideSheet(study.id)
  }

  function handleStudyFilter(query) {
    setFilterValue(query)
    if (query === '') {
      return setStudiesSet(studiesSetRaw)
    }

    setStudiesSet(
      studiesSetRaw.filter(study => {
        const name = study.name
        return name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      })
    )
  }

  return {
    studiesSet,
    loading,
    filterValue,
    countUsers,
    initialTabIndex,
    showStudyDetail,
    selectedStudy,
    onStudyCreation,
    handleStudyFilter,
    handleOpenSideSheet,
    handleCreateStudyCloseModal,
    handleCreateStudyOpenModal,
    handleStudyDetailsClose,
    showCreateStudyDialog
  }
}
