import { Pane } from 'evergreen-ui'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { formStyles } from '../styles/sharedComponentStyles'
import myTheme from '../styles/theme'

const useSharedStyles = createUseStyles({
  ...formStyles,
  wrapper: {
    width: '100%',
    marginBottom: '20px'
  },
  optionTitle: {
    marginLeft: '20px',
    marginTop: '0',
    width: '100%',
    backgroundColor: `${myTheme.colors.lightTeal}`,
    padding: '15px',
    '& span': {
      color: `${myTheme.colors.blueNavy}`,
      borderRadius: '3px',
      marginLeft: '20px',
      padding: '6px !important'
    }
  },
  optionDescription: {
    marginLeft: '45px',
    marginTop: '10px',
    textAlign: 'left',
    color: `${myTheme.colors.grey}`
  },
  optionTitleSelected: {
    composes: '$optionTitle',
    backgroundColor: `${myTheme.colors.mediumTeal}`
  }
})

interface ICreateStudyOption {
  title: string
  description: string
  visited: boolean
}

const CreateStudyOption = ({
  title,
  description,
  visited
}: ICreateStudyOption) => {
  const sharedClasses = useSharedStyles()
  return (
    <Pane className={sharedClasses.wrapper}>
      <Pane
        className={
          visited
            ? sharedClasses.optionTitleSelected
            : sharedClasses.optionTitle
        }
      >
        {title}
      </Pane>
      <Pane className={sharedClasses.optionDescription}>{description}</Pane>
    </Pane>
  )
}

export default CreateStudyOption
