import gql from 'graphql-tag'

export const GET_ALL_TASKS_QUERY = gql`
  query GetAllTasks {
    Task(order_by: { displayName: asc }) {
      id
      taskIdentifier
      displayName
      TaskDomain {
        id
        name
      }
    }
    TaskBattery(order_by: {displayName: asc}) {
      id
      displayName
      batteryIdentifier
      TaskDomain {
        id
        name
      }
    }
  }
`
