import React from 'react'
import { RadioGroup } from 'evergreen-ui'
import { useController } from 'react-hook-form'

const RadioField = ({
  label,
  name,
  disabled,
  defaultValue,
  options,
  control,
  ...rest
}) => {
  const { field } = useController({ control, name, defaultValue })
  return <RadioGroup {...field} label={label} options={options} {...rest} />
}

export default RadioField
