import React, { useEffect, useMemo } from 'react'
import { Pane } from 'evergreen-ui'
import { useFieldArray, useWatch } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { formStyles } from '../styles/sharedComponentStyles'
import CheckboxField from './forms/CheckboxField'
import {
  reportOptions,
  SUMMARY_ABBREVIATED_REPORT_TITLE,
  SUMMARY_REPORT_TITLE,
  KPSC_REPORT_TITLE
} from '../utils/constants'
import CreateStudyOption from './CreateStudyOption'

const useSharedStyles = createUseStyles({
  ...formStyles,
  wrapper: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    textAlign: 'left'
  },
  fields: {
    composes: '$fieldsLabel',
    width: '100%',
    height: '340px',
    overflowY: 'auto'
  },
  checks: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > div': {
      marginTop: '16px'
    },
    '& span': {
      width: '90%'
    }
  }
})

const ReportList = ({ control, study }) => {
  const sharedClasses = useSharedStyles()
  const { append, fields } = useFieldArray({ control, name: 'reports' })
  const reportsChecks = useWatch({ control, name: 'reports' })
  const controlledInputs = useMemo(
    () =>
      reportsChecks &&
      fields.map((field, idx) => ({
        ...field,
        ...reportsChecks[idx]
      })),
    [fields, reportsChecks]
  )
  useEffect(() => {
    if (
      reportOptions &&
      reportOptions.length > 0 &&
      (!controlledInputs || controlledInputs?.length === 0)
    ) {
      append(
        reportOptions.map(report => {
          const markAsTrue =
            (report.title === SUMMARY_REPORT_TITLE &&
              study?.hasSummaryReport) ||
            (report.title === SUMMARY_ABBREVIATED_REPORT_TITLE &&
              study?.hasAbbreviatedSummaryReport) ||
            (report.title === KPSC_REPORT_TITLE &&
              study?.hasSummaryReportBhaKpsc) ||
            false
          return {
            report,
            value: markAsTrue
          }
        })
      )
    }
  }, [reportOptions])

  return (
    <Pane className={sharedClasses.wrapper} is="article">
      <p className={sharedClasses.fieldsLabel}>
        Assign optional Summary Reports to your study.
      </p>
      <Pane className={sharedClasses.fields} id="manage-reports-dialog">
        {controlledInputs?.map((field, index) => (
          <Pane key={field.id}>
            <CheckboxField
              control={control}
              label={
                <CreateStudyOption
                  title={field.report.title}
                  description={field.report.description}
                  visited={field.value}
                />
              }
              defaultValue={field.value}
              name={`reports.${index}.value`}
              data-testid={`${index}-report-check`}
              className={sharedClasses.checks}
            />
          </Pane>
        ))}
      </Pane>
    </Pane>
  )
}

export default ReportList
