import { useQuery } from '@apollo/client'
import { Pane, toaster } from 'evergreen-ui'
import React, { useMemo } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { GET_ALL_TASKS_QUERY } from '../queries/getAllTasks'
import { formStyles } from '../styles/sharedComponentStyles'
import myTheme from '../styles/theme'
import CheckboxField from './forms/CheckboxField'
import LoadingSpinner from './LoadingSpinner'
import { BHA_SUB_TASKS, TABCAT_BHA_TASK_BATTERY_IDENTIFIER } from '../utils/constants'

const useSharedStyles = createUseStyles({
  ...formStyles,
  tasks: {
    height: '330px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  checkbox: {
    composes: '$darkerCheckbox',
    marginLeft: '20px',
    width: '100%',
    '& span': {
      color: `${myTheme.colors.blueNavy}`,
      borderRadius: '3px',
      marginLeft: '20px',
      padding: '6px !important'
    }
  },
  checkboxSelected: {
    composes: '$checkbox',
    '& span': {
      backgroundColor: `${myTheme.colors.mediumTeal}`
    }
  },
  labelSpans: {
    marginTop: '0 !important'
  }
})

export const TaskLabel = ({ field }) => {
  const sharedClasses = useSharedStyles()
  return (
    <Pane display="flex" justifyContent="space-between">
      <span
        className={sharedClasses.labelSpans}
        style={{ marginLeft: 0, paddingLeft: 0 }}
      >
        {field.task?.displayName || field.task?.name || ''}
      </span>
      <span
        className={sharedClasses.labelSpans}
        style={{
          marginLeft: '30%'
        }}
      >
        {field.task.TaskDomain?.name}
      </span>
    </Pane>
  )
}

const TASK_LIST_ERROR_MESSAGE =
  'Sorry, something went wrong getting the task list of checkboxes, please try again later.'



// If there are new taskBatteries add the logic here
const disableTaskInTaskList = (taskOrBatteryIdentifier, controlledInputs) => {
  const taskBHA =
    controlledInputs &&
    controlledInputs.find(
      task => task.task.batteryIdentifier === TABCAT_BHA_TASK_BATTERY_IDENTIFIER
    )

  const tasksValuesBHASubTasks = controlledInputs
    .filter(task => BHA_SUB_TASKS.includes(task.task.taskIdentifier))
    .map(task => task.value)

  if (taskOrBatteryIdentifier === TABCAT_BHA_TASK_BATTERY_IDENTIFIER) {
    if (tasksValuesBHASubTasks.includes(true)) {
      return true
    } else {
      return false
    }
  } else if (BHA_SUB_TASKS.includes(taskOrBatteryIdentifier)) {
    if (taskBHA?.value) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const TaskList = ({ control, currentTasks = [], description = '' }) => {
  const sharedClasses = useSharedStyles()
  const { append, fields } = useFieldArray({ control, name: 'tasks' })
  const taskChecks = useWatch({ control, name: 'tasks' })
  const controlledInputs = useMemo(
    () =>
      taskChecks &&
      fields.map((field, idx) => ({
        ...field,
        ...taskChecks[idx]
      })),
    [fields, taskChecks]
  )

  const { loading } = useQuery(GET_ALL_TASKS_QUERY, {
    onCompleted: result => {
      if (
        result?.Task &&
        result.Task.length > 0 &&
        (!controlledInputs || controlledInputs?.length === 0)
      ) {
        append(
          result.Task.filter(
            task => task.taskIdentifier !== 'edu.ucsf.tabcat-bha'
          ).map(task => ({
            task,
            value:
              currentTasks.some(
                t =>
                  t.task_id === task.id &&
                  t.__typename === 'Study_Task' &&
                  task.__typename === 'Task'
              ) || false
          }))
        )
      }
      if (
        result?.TaskBattery &&
        result.TaskBattery.length > 0 &&
        (!controlledInputs || controlledInputs?.length === 0)
      ) {
        append(
          result.TaskBattery.map(task => ({
            task,
            value:
              currentTasks.some(
                t =>
                  t.taskBattery_id === task.id &&
                  t.__typename === 'Study_TaskBattery' &&
                  task.__typename === 'TaskBattery'
              ) || false
          }))
        )
      }
    },
    onError: error => toaster.danger(TASK_LIST_ERROR_MESSAGE)
  })

  return (
    <Pane position="relative">
      <p>{description || 'Assign tasks to your study.'}</p>
      {loading && <LoadingSpinner />}
      <Pane className={sharedClasses.tasks}>
        {controlledInputs?.map((field, index) => (
          <Pane key={field.id}>
            <CheckboxField
              disabled={disableTaskInTaskList(
                field.task.taskIdentifier || field.task.batteryIdentifier,
                controlledInputs
              )}
              control={control}
              label={<TaskLabel field={field} />}
              defaultValue={field.value}
              name={`tasks.${index}.value`}
              data-testid={`${field.task.id}-task-check`}
              className={
                field.value
                  ? sharedClasses.checkboxSelected
                  : sharedClasses.checkbox
              }
            />
          </Pane>
        ))}
      </Pane>
    </Pane>
  )
}

export default TaskList
