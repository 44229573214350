import * as yup from 'yup'
import { validateRepeatedName } from '../../utils/filters'

export const studyFormSchema = studyNames =>
  yup.object().shape({
    name: yup
      .string()
      .typeError('Required')
      .required('Required')
      .trim()
      .test({
        message: 'A study with this name already exist, please change it',
        name: 'studyName',
        test: value => !validateRepeatedName(studyNames, value)
      })
  })

export const studyTaskManagementSchema = yup.object().shape({
  tasks: yup
    .array()
    .transform(task => task.filter(t => t.value))
    .required()
    .min(1)
})

export const studySiteManagementSchema = yup.object().shape({
  sites: yup
    .array()
    .transform(site => site.filter(t => t.value))
    .required()
    .min(1)
})
