import React, { useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Pane, toaster } from 'evergreen-ui'
import { useFieldArray, useWatch } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import { customListStyles, formStyles } from '../styles/sharedComponentStyles'
import myTheme from '../styles/theme'
import CheckboxField from './forms/CheckboxField'
import LoadingSpinner from './LoadingSpinner'
import { useQuery } from '@apollo/client'
import { GET_SITES_QUERY } from '../queries/getSites'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useGlobalStore } from '../hooks/useGlobalStore'

const useSharedStyles = createUseStyles({
  ...formStyles,
  ...customListStyles,
  sites: {
    height: '350px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  nosites: {
    textAlign: 'center',
    paddingTop: '80px'
  },
  checkbox: {
    composes: '$darkerCheckbox',
    marginLeft: '20px',
    width: '100%',
    '& span': {
      color: `${myTheme.colors.blueNavy}`,
      borderRadius: '3px',
      marginLeft: '20px',
      padding: '6px !important'
    }
  },
  checkboxSelected: {
    composes: '$checkbox',
    '& span': {
      backgroundColor: `${myTheme.colors.mediumTeal}`
    }
  },
  labelSpans: {
    marginTop: '0 !important'
  }
})

const SiteLabel = ({ field }) => {
  const sharedClasses = useSharedStyles()
  return (
    <Pane display="flex" justifyContent="space-between">
      <span
        className={sharedClasses.labelSpans}
        style={{ marginLeft: 0, paddingLeft: 0 }}
      >
        {field.site?.name}
      </span>
      <span
        className={sharedClasses.labelSpans}
        style={{
          marginLeft: '30%'
        }}
      >
        {field.site?.Site_Users_aggregate?.aggregate?.count || 0} Users
      </span>
    </Pane>
  )
}

const SITE_LIST_ERROR_MESSAGE =
  'Sorry, something went wrong getting the site list of checkboxes, please try again later.'

const SiteList = ({ control, currentSites = [], handleCreateSite }) => {
  const sharedClasses = useSharedStyles()
  const { realmId } = useGlobalStore()
  const { append, fields } = useFieldArray({
    control,
    name: 'sites'
  })
  const siteChecks = useWatch({ control, name: 'sites' })
  const controlledInputs = useMemo(
    () =>
      siteChecks &&
      fields.map((field, idx) => ({
        ...field,
        ...siteChecks[idx]
      })),
    [fields, siteChecks]
  )
  const noSites = !controlledInputs || controlledInputs.length === 0
  const { loading, data } = useQuery(GET_SITES_QUERY, {
    skip: !realmId,
    variables: {
      siteWhere: {
        realm_id: { _eq: realmId },
        isBlocked: { _eq: false }
      }
    },
    onError: _ => toaster.danger(SITE_LIST_ERROR_MESSAGE)
  })
  useEffect(() => {
    if (
      data?.Site?.length > 0 &&
      (!controlledInputs || controlledInputs?.length === 0)
    ) {
      append(
        data.Site.map(site => ({
          site,
          value: currentSites?.some(s => s.Site?.id === site.id) || false
        }))
      )
    } else if (data?.Site?.length > 0 && controlledInputs?.length > 0) {
      append(
        data.Site.filter(
          site => !controlledInputs.find(input => input.site.id === site.id)
        ).map(site => ({
          site,
          value: true
        }))
      )
    }
  }, [data])

  return (
    <Pane position="relative">
      <p>Assign sites to your study.</p>
      {loading && <LoadingSpinner />}
      <Pane className={sharedClasses.sites}>
        {noSites && !loading && (
          <Pane className={sharedClasses.nosites}>
            <p>There are no sites in your realm.</p>
            <Button
              className={sharedClasses.buttonStylesTerciary}
              onClick={handleCreateSite}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '10px' }} />
              Create a Site
            </Button>
          </Pane>
        )}
        {controlledInputs?.map((field, index) => (
          <Pane key={field.id}>
            <CheckboxField
              control={control}
              label={<SiteLabel field={field} />}
              defaultValue={field.value}
              name={`sites.${index}.value`}
              data-testid={`${field.site.id}-site-check`}
              className={
                field.value
                  ? sharedClasses.checkboxSelected
                  : sharedClasses.checkbox
              }
            />
          </Pane>
        ))}
      </Pane>
    </Pane>
  )
}

export default SiteList
